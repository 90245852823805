
import { ENV } from "../utils"

export class Favorite{

    base_api = ENV.BASE_API

    async getFavorite(accessToken, user){
        try {
            const url = `${this.base_api}/${ENV.API_ROUTES.FAVORITES}?emailUser=${user.email}`

            const params = {
                headers: {
                    Authorization: `Token ${accessToken}`
                },
            }
            const response = await fetch(url, params)
            const result = await response.json()

            if(response.status !== 200) throw result;
            return result

        } catch (error) {
            throw error
        }
    }

    async createFavorite(accessToken, User, id_Work){

        try {
            const url = `${this.base_api}/${ENV.API_ROUTES.FAVORITES}`
            const params = {
                method: "POST",
                headers: {
                    Authorization: `Token ${accessToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    User:   User,
                    idWork: id_Work,
                    status: true
                })
            }
            const response = await fetch(url, params)
            const result = await response.json()

            if(response.status !== 201) throw result;
            return result
        } catch (error) {
            throw error
        }
    }

    async deleteFavorites(accessToken, idFavorite){
        try {
            const url = `${this.base_api}/${ENV.API_ROUTES.FAVORITES}/${idFavorite}`

            const params = {
                method: "DELETE",
                headers: {
                    Authorization: `Token ${accessToken}`
                }
            }
            const response = await fetch(url, params)
            const result = await response.json()

            if(response.status !== 200) throw result;
            return result
        } catch (error) {
            throw error
        }
    }

    async updateFavorites(accessToken, idFavorite, data){
        try {
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                formData.append(key, data[key])
            })

            const url = `${this.baseApi}/${ENV.API_ROUTES.FAVORITES}/${idFavorite}`
            const params = {
                method: "PATCH",
                headers: {
                    Authorization: `Token ${accessToken}`
                },
                body: formData,
            }

            const response = await fetch(url, params)
            const result = await response.json()

            if(response.status !== 200) throw result;
            return result

        } catch (error) {
            throw error
        }
    }
}