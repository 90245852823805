import React, { useState, useCallback, useEffect } from 'react'
import { Form } from "semantic-ui-react"
import { ErrorMessage, useFormik } from "formik"
import { useDropzone } from "react-dropzone"
import Dropzone from 'react-dropzone'
import { Work } from '../../../api'
import { useAuth } from '../../../hooks'
import { initialValues, validationSchema } from "../WorkValuesForm/WorkValuesForm"
import { ModalConfirm } from '../ModalConfirm/ModalConfirm'
import axios from 'axios'
import { Container } from 'reactstrap'

const workController = new Work()

export function WorkForm(props) {
  const { close, onReload, work, valueModalConfirm, validateImg } = props
  const { accessToken } = useAuth()
  const { user } = useAuth()

  const [allLinks, setAllLinks] = useState("")
  const [images, setImages] = useState([])
  const [errorForm, setErrorForm] = useState(null)
  const [newArray, setNewArray] = useState([])

  const [errorRegister, newErrorRegister] = useState(null);
  const [showModalConfirmRegister, setShowModalConfirmRegister] = useState("ModalConfirmHide")

  useEffect(() => {
    if (errorRegister != null) {
      let stopRegister
      clearTimeout(stopRegister)
      setShowModalConfirmRegister("ModalConfirmHide")
      setShowModalConfirmRegister("ModalConfirm")
      setTimeout(() => {
        stopRegister = setShowModalConfirmRegister("ModalConfirmHide")
      }, [2500])
    }
  }, [errorRegister])

  const formik = useFormik({
    initialValues: initialValues(work),
    validationSchema: validationSchema(work),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      let horaTrabajos = Number(formValue.date.replace("T", " ").split("-").join("").split(":").join("").split(" ").join(""));
      let dateActuality = new Date()
      let horaActual = Number(dateActuality.toISOString().slice(0, 16).split("-").join("").split(":").join("").split("T").join("")) - 500;
      try {
        if (!work) {
          if (horaActual >= horaTrabajos) {
            newErrorRegister("Elige una fecha futura")
          } else {
              if(allLinks !== ""){
                formValue.imageWork = allLinks
                await workController.createWork(accessToken, formValue, user)
                onReload()
                close()
                valueModalConfirm(true)
                window.location.reload(false);
              }else{
                newErrorRegister("Agregue minimo una imagen")
              }
            }
        } else {
          if (horaActual >= horaTrabajos) {
            newErrorRegister("Elige una fecha futura")
          } else {
            if(allLinks !== ""){
              formValue.imageWork = allLinks
              await workController.updateWork(accessToken, work._id, formValue)
              onReload()
              close()
              window.location.reload(false);
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  })

  function closeForm() {
    onReload()
    close()
  }

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0]
    formik.setFieldValue("imageWork", URL.createObjectURL(file))
    formik.setFieldValue("fileAvatar", file)
  })

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    onDrop,
  })

  const [showModalConfirm, setShowModalConfirm] = useState("ModalConfirmHide")

  useEffect(() => {
    if (errorForm != null) {
      let stopLogin
      clearTimeout(stopLogin)
      setShowModalConfirm("ModalConfirmHide")
      setShowModalConfirm("ModalConfirm")
      setTimeout(() => {
        stopLogin = setShowModalConfirm("ModalConfirmHide")
      }, [2500])
    }
  }, [errorForm])

  const [nameImg, setNameImg] = useState(null)
  const [loading, setLoading] = useState(false)


  const handleImg = (files) => {
    let links = ""
    const uploaders = files.map((file) => {
      const formData = new FormData()
      formData.append("file", file)
      formData.append("tags", `condeinfuse, medium, gist`)
      formData.append("upload_preset", "imgWorks")
      formData.append("api_key", "537322317274672")
      formData.append("timestamp", (Date.now() / 1000 | 0))
      setLoading(true)
      return axios
        .post("https://api.cloudinary.com/v1_1/dpuasqaeb/image/upload", formData, {
          headers: { "X-Requested-With": "XMLHttpRequest" },
        })
        .then((response) => {
          const data = response.data
          const fileURL = data.secure_url
          links += fileURL + " - "
          setAllLinks(links)
        })
    })
    axios.all(uploaders).then(() => {
      setLoading(false)
      const arrayLinks = allLinks.split(" - ")
      arrayLinks.forEach((Element) => {
        if (Element === "") {
          arrayLinks.pop(Element)
        }
        allLinks += Element
      })
    })
  }

  const [modalLoad, setModalLoad] = useState(loading)
  const [modalLoadtext, setModalLoadtext] = useState()
  const [modalLoadSymbol, setModalSymbol] = useState()

  useEffect(() => {
    if (loading === true) {
      setModalLoad("confirmImgs")
      setModalLoadtext("Subiendo imagenes")
      setModalSymbol(<>
        <div className="bar bar1"></div>
        <div className="bar bar2"></div>
        <div className="bar bar3"></div>
        <div className="bar bar4"></div>
        <div className="bar bar5"></div>
        <div className="bar bar6"></div>
        <div className="bar bar7"></div>
        <div className="bar bar8"></div>
      </>)
    } else {
      setModalLoadtext("Imagenes guardadas")
      setModalSymbol(<span id='modalConfirmDone' className="material-symbols-outlined">
        done
      </span>)
      setTimeout(() => {
        setModalLoad("confirmImgsHide")
      }, 2000);
    }
  }, [loading])

  const [confirmQuantity, setConfirmQuantity] = useState(false)

  const changeImg = (files) => {
    const filesList = files.target.files;
    const imgArray = Array.from(filesList)
    if (imgArray.length > 0 && imgArray.length <= 6) {
      setImages("")
      let name = ""
      imgArray.forEach(Element => {
        const save = ((prevImg) => [...prevImg, Element])
        setImages(save)
        name += `${Element.name},`
      })
      setNameImg(name)
      setConfirmQuantity(false)
    } else {
      setConfirmQuantity(true)
      validateImg(false)
    }
    setNewArray()
    setNewArray(imgArray)
  }

  useEffect(() => {
    if (newArray.length === 0) {
    } else {
      if (confirmQuantity === false) {
        handleImg(newArray);
      } else {
        // console.log("las imagenes superan en limite");
      }
    }
  }, [newArray])

  const DeleteImg = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
    setNewArray(updatedImages)
    let name = ""
    images.forEach(Element => {
      name += `${Element.name},`
    })
    setNameImg(name)
  };

  const renderImgs = () => {
    return images.map((imagen, index) => (
      <section id='imgPrev' key={index}>
        <img
          src={URL.createObjectURL(imagen)}
          alt={`Imagen ${index + 1}`}
          onClick={() => openImageInNewWindow(index)}
        />
        <div id='ContBtnImg'>
          <button id='btnImg' onClick={() => DeleteImg(index)}>
            <span className="material-symbols-outlined">delete</span>
          </button>
        </div>
      </section>
    ));
  };

  const openImageInNewWindow = (index) => {
    const imageUrl = URL.createObjectURL(images[index]);
    window.open(imageUrl);
  };

  return (
    <Form className='user-form' onSubmit={formik.handleSubmit}>
      <div id={modalLoad}>
        <p>
          {modalLoadtext}
        </p>
        <div className="middle">
          {modalLoadSymbol}
        </div>
      </div>

      <ModalConfirm idModal={showModalConfirm} text="Trabajo creado" symbol={true} />
      <ModalConfirm idModal={showModalConfirmRegister} text={errorRegister} symbol={false} />

      <div className='containerDrop'>
        <Container className='containerDrop2'>

          <Dropzone
            className='dropzone'
            onDrop={handleImg}
            onChange={(e) => { setImages(e.target.value) }}
            value={images}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div id='divDropText' {...getRootProps({ className: "dropzone" })}>
                  {images.length !== 0 ? <div id='textFile2'>{nameImg}</div> : <div id='textFileEmpty2'><span className="material-symbols-outlined">
                    upload
                  </span>Seleccionar archivo</div>}
                </div>
                <input multiple type='file' id='inputImage'{...getInputProps} onChange={changeImg} />
              </section>
            )}
          </Dropzone>
        </Container></div>

      {images.length !== 0 ? <div id='imagesWorks'>
        {renderImgs()}
      </div> : null}

      <Form.Input name="name" placeholder="Trabajo" onChange={formik.handleChange}
        value={formik.values.name} error={formik.errors.name}
      />
      <Form.Input name="description" placeholder="Descripcion" onChange={formik.handleChange}
        value={formik.values.description} error={formik.errors.description}
      />
      <Form.Input name="date" type='datetime-local' placeholder="Fecha" onChange={formik.handleChange}
        value={String(formik.values.date)} error={formik.errors.date}
      />
      <Form.Input name="address" placeholder="Direccion" onChange={formik.handleChange}
        value={formik.values.address} error={formik.errors.address}
      />
      <Form.Input type='number' name="price" placeholder="Pago" onChange={formik.handleChange}
        value={formik.values.price} error={formik.errors.price}
      />
      <Form.Input type='number' name="postulates" placeholder="Cantidad de postulados" onChange={formik.handleChange}
        value={formik.values.postulates} error={formik.errors.postulates}
      />
      <div className='formDivButton'>
        <Form.Button type='button' primary fluid onClick={closeForm} >
          Cancelar
        </Form.Button>
        <Form.Button type='submit' primary fluid loading={formik.isSubmitting} >
          Publicar
        </Form.Button>
      </div>
    </Form>
  )
}