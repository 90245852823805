import React, { useState, useRef, useEffect } from 'react'
import { ENV } from '../../../utils'
import { useAuth } from '../../../hooks'
import { Comment, Work } from "../../../api";
import { StarRating } from '../StartRating/StartRating'
import ButtonForm from '../ButtonForm/ButtonForm'

const commentController = new Comment()
const workController = new Work()

export function PublishedWorksItem(props) {
  const { work, onReload, search } = props
  const { user, accessToken } = useAuth()
  const [modalButtonsWork, setModalButtonsWork] = useState("completedForm");
  const [starts, setStarts] = useState("");
  const [viewImage, setViewImage] = useState("")

  function pathWork() {
    window.location.href = `${ENV.BASE_PATH}/trabajos/${work._id}`;
  }

  useEffect(() => {
    let imagenes = work.imageWork
    let arrayImage = imagenes.split(" - ")

    arrayImage.pop()
    setViewImage(arrayImage[0])
  }, [])

  const commentRef = useRef("")

  const completedWork = () =>
    setModalButtonsWork(
      modalButtonsWork === "completedForm" ? "completedForm2" : "completedForm"
    );

  async function sendCalification() {
    let commentMsg = commentRef.current.value

    await commentController.createComment(accessToken, user.email, work.userWork, starts, commentMsg)
    await workController.updateWork(accessToken, work._id, {
      completedUser: true
    })

    completedWork();
    window.location.reload(false);
  }

  const handleMesajeCambiado = (value) => {
    setStarts(String(value))
  }

  return (
    <>
      {work.userPostulate === user.email ?
         <article className="card">
         {user === null ? (
              ""
            ) : (
              <>
                {work.completedWork ? "" :<div className="optionsFavRepHistorical">
                <div className="optionsCompletedHistorical">
                  <button title="Realizado" onClick={completedWork} className="btnCompleted"><span className="material-symbols-outlined btnDone">add_task</span></button>
                </div></div>}
              </>
            )}
         <img onClick={pathWork}
           className="temporary_text"
           src={
             work.imageWork
               ? `${viewImage}`
               : "https://th.bing.com/th/id/R.c3631c652abe1185b1874da24af0b7c7?rik=XBP%2fc%2fsPy7r3HQ&riu=http%3a%2f%2fpluspng.com%2fimg-png%2fpng-user-icon-circled-user-icon-2240.png&ehk=z4ciEVsNoCZtWiFvQQ0k4C3KTQ6wt%2biSysxPKZHGrCc%3d&risl=&pid=ImgRaw&r=0"
           }
           alt=""
         />
         <div className="card_content" onClick={pathWork}>
           <span className="card_title" title={work.name}>{work.name}</span>
           <span className="card_subtitle">
             <div id="cont-card-p">
               <p id="sub-description">Pago:</p>
               <p id="sub-description2">{work.price}</p>
             </div>
             <div id="cont-card-p">
               <p id="sub-description">Dirección:</p>
               <p id="sub-description2">{work.address}</p>
             </div>
             <div id="cont-card-p">
               <p id="sub-description">Fecha:</p>
               <p id="sub-description2">{work.date}</p>
             </div>
             <div id="cont-card-p">
               <p id="sub-description">Estado:</p>
               <p id="sub-description2">{`${work.completedWork ? work.completedUser ? "Terminado" : "En proceso" : "En proceso"}`}</p>
             </div>
           </span>
           <p className="card_description">
             <div id="cont-card-description">
               <p id="sub-description">Descripción:</p>
               <p id="work-description">{work.description}</p>
             </div>
           </p>
         </div>
       </article>: ""}
        
      <div className={modalButtonsWork}>
        <h2>{`Calificar el trabajo ${work.name}`}</h2>
        <div>
          <p className="textComment">Agrega un comentario a cerca de la persona que realizo el trabajo.</p>
          <input className="inputCalification" type="text" ref={commentRef} />
        </div>
        <StarRating starts={handleMesajeCambiado}></StarRating>
        <div className="divBtnCalification">
          <button className="btnCalification" onClick={completedWork}>Cancelar</button>
          <button className="btnCalification" onClick={sendCalification}>Enviar</button>
        </div>
      </div>
    </>
  )
}
