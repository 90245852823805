import React, { useState, useEffect } from 'react'
import { Anclas } from '../../UI/Anclas/Anclas'
import ButtonForm from '../../UI/ButtonForm/ButtonForm'
import { User } from "../../../api";
import { useAuth } from "../../../hooks"
import { BasicModal } from '../../UI/BasicModal/BasicModal';
import { UserForm } from '../../UI/UserForm/UserForm';
import { ENV } from '../../../utils';
import { ModalConfirm } from '../../UI/ModalConfirm/ModalConfirm';

const userController = new User()

export const ContainerProfile = () => {
  const [user, setUser] = useState(null)
  const { accessToken } = useAuth()

  const [reload, setReload] = useState(false)
  const onReload = () => setReload((prevState) => !prevState)

  const [modalLogout, setModalLogout] = useState("modalLogout1")

  const [showModal, setShowModal] = useState("modalProfile2")
  const [titleModal, setTitleModal] = useState("")
  const [closeModal, setCloseModal] = useState(0)
  const { logout } = useAuth();

  const onOpenCloseModal = () => {
    setCloseModal(1)
    setShowModal(showModal === "modalProfile2" ? "modalProfile3" : "modalProfile2")
    setOverlayMenu(showModal === "modalProfile2" ? "ModalContainer" : "ModalContainerHide")
  }

  const openModalLogout = () => {
    setCloseModal(2)
    setModalLogout(modalLogout === "modalLogout1" ? "modalLogout" : "modalLogout1")
    setOverlayMenu(modalLogout === "modalLogout1" ? "ModalContainer" : "ModalContainerHide")
  }

  useEffect(() => {
    (async () => {
      try {
        setUser(null)
        const response = await userController.getMe(accessToken)
        setUser(response)
      } catch (error) {
        console.error(error);
      }
    })()
  }, [reload])

  const [userLeft, setUserLeft] = useState(1)

  const openUpdateUser = () => {
    setTitleModal(`Actualizar ${user.email}`)
    onOpenCloseModal()
  }
  const miPerfil = () => {
    setUserLeft(1)
  }
  const Configuracion = () => {
    setUserLeft(2)
  }
  const Seguridad = () => {
    setUserLeft(3)
  }
  const Otros = () => {
    setUserLeft(4)
  }

  const [showModalConfirm, setShowModalConfirm] = useState("ModalConfirmHide")

  const ModalConfirmValue = (value) => {
    if (value === true) {
      let timeUpdate
      clearTimeout(timeUpdate)
      setShowModalConfirm("ModalConfirmHide")
      setShowModalConfirm("ModalConfirm")
      timeUpdate = setTimeout(() => {
        setShowModalConfirm("ModalConfirmHide")
      }, [3000])
    }
  }

  const [overlayMenu, setOverlayMenu] = useState("ModalContainerHide")

  const onLogout = () => {
    if (!user) {
      let timeUpdate
      clearTimeout(timeUpdate)
      setShowModalConfirm("ModalConfirmHide")
      setShowModalConfirm("ModalConfirm")
      timeUpdate = setTimeout(() => {
        setShowModalConfirm("ModalConfirmHide")
      }, [3000])
    } else {
      logout();
      window.location.href = `${ENV.BASE_PATH}/auth?#`;
    }
  };

  return (
    <>
      <ModalConfirm idModal={showModalConfirm} text="No hay usuario" symbol={true} />
      <div id='ProfileMain'>
        <div className={modalLogout}>
          <p>¿Deseas cerrar sesion?</p>
          <div className='divBtnLogout'>
            <button onClick={onLogout}>Si</button>
            <button onClick={openModalLogout}>No</button>
          </div>
        </div>
        {/* Perfil parte Izquierda */}
        <section id='ProfileLeft'>
          {/* Mi perfil */}
          {userLeft === 1 ? (<>
            <div id='UserProfileUp'>
              <p>{user ? `${user.firstname} ${user.lastname}` : ""}</p>
              <img id='imgUser' src={user ? user.avatar !== "" ? `${user.avatar}` : "https://th.bing.com/th/id/R.c3631c652abe1185b1874da24af0b7c7?rik=XBP%2fc%2fsPy7r3HQ&riu=http%3a%2f%2fpluspng.com%2fimg-png%2fpng-user-icon-circled-user-icon-2240.png&ehk=z4ciEVsNoCZtWiFvQQ0k4C3KTQ6wt%2biSysxPKZHGrCc%3d&risl=&pid=ImgRaw&r=0" : ""} />
              <p>{user ? `${user.email}` : ""}</p>
            </div>
            <div id='UserProfileDown'>
              <div id='anchorsInfo'>
                <Anclas
                  estilos="AnInfo"
                  enlace=""
                  textoAncla="⭐⭐⭐⭐⭐"
                />
              </div>
            </div>
          </>) : ("")}

          {/* Configuracion */}
          {userLeft === 2 ? (<>
            <ButtonForm
              styleButton="BtnProfile"
              onClick={openUpdateUser}
              content="Actualizar mis datos"
            />
          </>) : ("")}

          {/* Seguridad */}
          {userLeft === 3 ? (<><p>Aceptación de los Términos y Condiciones
Al utilizar este aplicativo, aceptas estar legalmente obligado por estos términos y condiciones, así como por nuestra política de privacidad. Si no estás de acuerdo con alguno de los términos o condiciones aquí establecidos, te rogamos que no utilices el aplicativo.

Uso del Aplicativo
2.1. El objetivo de este aplicativo es permitir a los usuarios publicar y buscar vacantes de trabajo.
2.2. Solo se permite el uso del aplicativo para fines legales y éticos. No se podrán publicar o difundir contenidos ilegales, difamatorios, obscenos, discriminatorios, violentos o que inciten al odio.
2.3. El contenido publicado en el aplicativo es responsabilidad exclusiva de quien lo ha publicado. No asumimos responsabilidad alguna por la exactitud, integridad o legalidad de dicho contenido.

Registro y Cuenta de Usuario
3.1. Para utilizar todas las funcionalidades del aplicativo, es necesario registrarse y crear una cuenta de usuario.
3.2. Debes proporcionar información precisa y actualizada al registrarte. No puedes hacer uso de identidades falsas ni suplantar a otras personas o entidades.
3.3. Eres responsable de mantener la confidencialidad de tu nombre de usuario y contraseña. Cualquier actividad que se realice en tu cuenta será de tu exclusiva responsabilidad.

Publicación de Vacantes
4.1. Al publicar una vacante en este aplicativo, garantizas que tienes la autoridad necesaria para ofrecer dicha posición laboral y que la información proporcionada es precisa y veraz.
4.2. No permitimos la publicación de vacantes que infrinjan derechos de terceros o que promuevan trabajos ilegales o no éticos.
4.3. Nos reservamos el derecho de eliminar o editar cualquier vacante publicada que consideremos inapropiada o que infrinja estos términos y condiciones.</p></>) : ("")}

          {/* Otros */}
          {userLeft === 4 ? (<><p>"Otros"</p></>) : ("")}

        </section>

        {/* Perfil parte Derecha */}
        <section id='ProfileRight'>
          <ButtonForm
            styleButton="BtnProfile"
            onClick={miPerfil}
            content="Mi Perfil"
          />
          <ButtonForm
            styleButton="BtnProfile"
            onClick={Configuracion}
            content="Configuracion"
          />
          <ButtonForm
            styleButton="BtnProfile"
            onClick={Seguridad}
            content="Seguridad"
          />
          <ButtonForm
            styleButton="BtnProfile-logout"
            onClick={openModalLogout}
            content="Cerrar sesión"
          />
        </section>
      </div>
      <ModalConfirm idModal={showModalConfirm} text="Perfil Actualizado correctamente" symbol={true} />
      <section className={overlayMenu}>
        <button id="btnCloseMenu" onClick={closeModal === 1 ? onOpenCloseModal : openModalLogout}></button>
        {user !== null ?
          <BasicModal show={showModal} title="Editar perfil">
            <UserForm valueModalConfirm={ModalConfirmValue} close={onOpenCloseModal} onReload={onReload} user={user} />
          </BasicModal> : ""}
      </section>
    </>
  )
}
