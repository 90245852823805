import React,{ useEffect, useState } from "react";
import { Logo } from "../Logo/Logo";
import { Anclas } from "../Anclas/Anclas";

export const ContenedorLogo = ({Menu}) => {

  const [MenuIsOpen,setMenuIsOpen] = useState(false)

  const closeMenu = ()=>{
    MenuIsOpen === false ? setMenuIsOpen(true) : setMenuIsOpen(false)
  }

  useEffect(()=>{
    Menu(MenuIsOpen)
  },[MenuIsOpen])

  return (
    <div id="ContMenuLog">
      <button id="iconMenuHeader" className="material-symbols-outlined" onClick={closeMenu}>menu</button>

      <Anclas
        estilos="ContenedorLogo"
        textoAncla={<Logo />}
        root="/"
        enlace="/"
      />
    </div>
  );
};
