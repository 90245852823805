import React, { useState, useEffect } from 'react'
import ImageBottomless from '../../UI/ImageBottomless/ImageBottomless'
import TitleWorkServices from '../../UI/TitleWorkServices/TitleWorkServices'
import TitleRegister from '../../UI/TitleRegister/TitleRegister'
import InputName from '../../UI/InputName/InputName'
import ButtonForm from '../../UI/ButtonForm/ButtonForm'
import { Anclas } from '../../UI/Anclas/Anclas'
import { Auth } from '../../../api'
import { useNavigate } from 'react-router-dom'
import { ENV } from '../../../utils'
import { ModalConfirm } from '../../UI/ModalConfirm/ModalConfirm'

const authController = new Auth()

export function RecoverPassword() {
  const navigate = useNavigate()
  const [email, newEmail] = useState("")
  const [error, newError] = useState("")

  let user = {
    email: email,
  }

  const [showModalConfirm, setShowModalConfirm] = useState("ModalConfirmHide")

  async function sendForm() {
    let timeUpdate
    clearTimeout(timeUpdate)
    setShowModalConfirm("ModalConfirmHide")
    setShowModalConfirm("ModalConfirm")
    timeUpdate = setTimeout(() => {
      setShowModalConfirm("ModalConfirmHide")
    }, [3000])
    try {
      newError("")
      await authController.recoverPassword(user.email)
      window.location.href = `${ENV.BASE_PATH}/auth?#`;
    } catch (error) {
      newError(`${error.msg}`)
    }
  }

  function pathWork() {
    navigate("/");
  }

  return (
    <>
      <ModalConfirm idModal={showModalConfirm} text="Te enviaremos un correo" symbol={true} />
      <div id='Login'>
        <div className='containerLogin'>
          <div className='LoginCard'>
            <div className='formLogin'>
              <div className="imgLogoRecover">
                <ImageBottomless click={pathWork} />
              </div>
              <TitleRegister content="Recuperar contraseña" />
              <InputName value={email} style2="inputLogin" type="email" content="E-mail"
                onChange={(e) => { newEmail(e.target.value) }}
              />
              <ButtonForm onClick={sendForm} styleButton="btnLogin" content="Recuperar" />
              <p>{error}</p>
              <div className='informationLogin'>
                <Anclas
                  estilos="Ancla"
                  textoAncla="Iniciar sesion"
                  enlace="/auth"
                />
              </div>
            </div>
          </div>
          <div className='containImgLogin'>
            <img className='imgLogin' src="" alt="" />
          </div>
        </div>
      </div>
    </>

  )
}
