import React, { useState, useEffect } from "react";
import { map } from "lodash";
import { ENV } from "../../../utils";
import { Confirm } from "semantic-ui-react";
import { Work, Notification } from "../../../api";
import { useAuth } from "../../../hooks";
import { useNavigate } from "react-router-dom";
import { Anclas } from "../Anclas/Anclas";
import { ModalConfirm } from "../ModalConfirm/ModalConfirm";

const workController = new Work()
const notificationController = new Notification()

export function PostulatesItem(props) {
  const navigate = useNavigate()
  const { postulate, users, path } = props;
  const { accessToken } = useAuth()

  const [showConfirm, setShowConfirm] = useState(false)
  const [confirmMessage, setConfirmMessage] = useState("")
  const [viewUser, setViewUser] = useState(null);
  const [viewWork, setViewWork] = useState(null);

  const onOpenCloseConfirm = () => setShowConfirm((prevState) => !prevState)

  useEffect(() => {
    (async () => {
      const response = await workController.getWorkPath(path)
      setViewWork(response)
    })()
  }, [])

  const [showModalConfirm, setShowModalConfirm] = useState("ModalConfirmHide")
  const [textModal, setTextModal] = useState()

  const onSelectionPostulate = async () => {
    try {
      if (viewWork !== null) {
        await workController.updateWork(accessToken, path, {
          userPostulate: viewUser.email,
          status: false
        })

        const hoy = new Date(Date.now());
        let now = (hoy.toISOString().split(".")[0]).replace("T", " ").slice(0, 16).replace(13, "1")

        let arrayDeCaracteres = now.split('');
        arrayDeCaracteres[12] = Number(now[12]) - 5;
        let nuevoString = arrayDeCaracteres.join('');

        let notification = {
          title: "Has sido seleccionado",
          textNotification: `Has sido seleccionado para realizar el trabajo ${viewWork.name} ahora puedes chatear con el ofertador`,
          date: `${nuevoString}`,
          id_Work: `${viewWork._id}`,
          id_User: `${viewUser.email}`
        }

        await notificationController.createNotification(accessToken, notification)
        let timeUpdate
        clearTimeout(timeUpdate)
        setTextModal(`${viewUser.firstname} ${viewUser.lastname} ha sido seleccionado`)
        setShowModalConfirm("ModalConfirmHide")
        setShowModalConfirm("ModalConfirm")
        timeUpdate = setTimeout(() => {
          setShowModalConfirm("ModalConfirmHide")
          navigate("/")
        }, [3000])
        onOpenCloseConfirm()
      }
    } catch (error) {
      console.error(error);
    }
  }

  const openSelection = () => {
    setConfirmMessage(`Seleccionar usuario ${viewUser.email}`)
    onOpenCloseConfirm()
  }

  let postulationMe = map(users, (post) => {
    if (post.email === postulate.userPostulate) {
      return post;
    }
  });
  useEffect(() => {
    map(postulationMe, (post) => {
      if (post != null) {
        setViewUser(post);
      }
    });
  }, [postulationMe]);

  return (
    <>
      <ModalConfirm idModal={showModalConfirm} text={textModal} symbol={true} />
      <div className="peoplePostulation">
        <img className="imgPerson" src={viewUser ? viewUser.avatar ? `${viewUser.avatar}` : "https://th.bing.com/th/id/R.c3631c652abe1185b1874da24af0b7c7?rik=XBP%2fc%2fsPy7r3HQ&riu=http%3a%2f%2fpluspng.com%2fimg-png%2fpng-user-icon-circled-user-icon-2240.png&ehk=z4ciEVsNoCZtWiFvQQ0k4C3KTQ6wt%2biSysxPKZHGrCc%3d&risl=&pid=ImgRaw&r=0" : ""} alt="" />
        <div>
          <p>{viewUser ? `Usuario: ${viewUser.firstname}` : ""}</p>
          <Anclas enlace={viewUser ? `/user/${viewUser._id}` : ""} textoAncla={viewUser ? `Email: ${viewUser.email}` : ""} />
        </div>
        <button className="seleccionar" onClick={openSelection}>Seleccionar</button>
      </div>
      <Confirm className='confirmModal' open={showConfirm} onCancel={onOpenCloseConfirm}
        onConfirm={onSelectionPostulate}
        content={confirmMessage} size='mini'
      />
    </>
  );
}
