import * as Yup from "yup"

export function initialValues(work){
    return{
        imageWork: work?.imageWork || "",
        fileAvatar: null,
        name: work?.name || "",
        description: work?.description || "",
        date: work?.date || "",
        address: work?.address || "",
        price: work?.price || "",
        postulates: work?.postulates || "",
    }
}

export function validationSchema(work){
    return Yup.object({
        imageWork: Yup.string(),
        name: Yup.string().required("Campo obligatorio*").max(30, "Maximo 30 caracteres"),
        description: Yup.string().required("Campo obligatorio*").max(250, "Maximo 30 caracteres"),
        date: Yup.string().required("Campo obligatorio*"),
        address: Yup.string().required("Campo obligatorio*").max(30, "Maximo 30 caracteres"),
        price: Yup.number().required("Campo obligatorio*"),
        postulates: Yup.number().required("Campo obligatorio*"),
    })
}