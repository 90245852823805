import React from "react";
import { Anclas } from "../../UI/Anclas/Anclas";

export const NoUserFavorite = () => {
  return (
    <section id="NoUserPersonalized">
      <div id="ContainerIconAnchor">
        <span id="iconAnchor" className="material-symbols-outlined">hotel_class</span>
      </div>

      <h2>Mira tus trabajos favoritos.</h2>
      <p>Debes acceder a tu cuenta para ver tus favoritos.</p>
      <Anclas
        estilos="AnchorAccount"
        enlace="/auth"
        textoAncla={
          <div id="account">
            <span className="material-symbols-outlined">account_circle</span>
            Acceder
          </div>
        }
      />
    </section>
  );
};
