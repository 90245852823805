import React, { useState } from 'react';

export const StarRating = ({starts}) => {
  const [rating, setRating] = useState(0);

  const handleClick = (value) => {
    setRating(value);
    starts(value)
  };

  return (
    <div className='divStarts'>
      <p className="textComment">Califica con estrellas a la persona que realizo el trabajo.</p>
      {[1, 2, 3, 4, 5].map((value) => (
        <span
          key={value}
          className={`star${value <= rating ? 'active' : ''}`}
          onClick={() => handleClick(value)}
        >
          &#9733;
        </span>
      ))}
    </div>
  );
};