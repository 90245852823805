import React from 'react'

export const ModalConfirm = ({ idModal, text, symbol }) => {
  return (
    <section id={idModal}><p>{text}</p>{symbol === true ? <span id='modalConfirmDone' className="material-symbols-outlined">
      done
    </span> : <span id="modalConfirmError" className="material-symbols-outlined">
      close
    </span>}</section>
  )
}

{/* <ModalConfirm idModal={showModalConfirm} text="Perfil Actualizado correctamente"/> */ }

// const [showModalConfirm,setShowModalConfirm] = useState("ModalConfirmHide")

//   const ModalConfirmValue = () =>{
//     let value =true
//     if(value===true){
//       setShowModalConfirm("ModalConfirm")
//       setTimeout(()=>{
//         setShowModalConfirm("ModalConfirmHide")
//       },[3000])
//     }
//   }