import React from 'react'
import { HeaderHome}  from '../../Layouts/HeaderHome/HeaderHome'
import { useParams } from 'react-router';
import { NoUserPostulate } from '../../Layouts/NoUsers/NoUserPostulates';
import { NoUserChat } from '../../Layouts/NoUsers/NoUserChat';
import { NoUserHistory } from '../../Layouts/NoUsers/NoUserHistory';
import { NoUserFavorite } from '../../Layouts/NoUsers/NoUserFavorite';

const NoUserAnchor = () => {
    const { id } = useParams()
  return ( 
    <section className='NoUserAnchor'>
        <HeaderHome/>
        {id === "1" ? <NoUserPostulate/> : ""}
        {id === "2" ? <NoUserChat/> : ""}
        {id === "3" ? <NoUserHistory/> : ""}
        {id === "4" ? <NoUserFavorite/> : ""}
    </section>
  )
}

export default NoUserAnchor
