import React, { useState, useEffect, useRef } from "react";
import { ENV } from "../../../utils";
import { Work, Report, Favorite } from "../../../api";
import { useAuth } from "../../../hooks";
import { useNavigate } from "react-router";
import ButtonForm from "../ButtonForm/ButtonForm";
import { ReportOptions } from "../ReportOptions/ReportOptions";

const workController = new Work();
const reportController = new Report();
const favoriteController = new Favorite();

export function WorkItem(props) {
  const { work, horaActual } = props;
  const useAuth2 = useAuth();
  const { user, accessToken } = useAuth();
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState("modalReport");
  const [modalButtonsWork, setModalButtonsWork] = useState("work-buttons2");
  const [Heart, setHeart] = useState(false)
  const [favorite, setFavorite] = useState()
  const [viewImage, setViewImage] = useState("")
  const [optionsShow, setOptionsShow] = useState("optionsFavRep")

  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const inputRef5 = useRef(null);

  useEffect(() => {
    (async () => {
      let horaTrabajo = Number(
        work.date
          .replace("T", " ")
          .split("-")
          .join("")
          .split(":")
          .join("")
          .split(" ")
          .join("")
      );
      if (work.status === true) {
        if (horaActual >= horaTrabajo) {
          await workController.deleteWork(accessToken, work._id);
        }
      }
    })();
  }, []);

  useEffect(() => {
    let imagenes = work.imageWork
    let arrayImage = imagenes.split(" - ")
    
    arrayImage.pop()
    setViewImage(arrayImage[0])
  }, [])

  const onOpenCloseModal = () => setShowModal(showModal === "modalReport" ? "modalReport2" : "modalReport");

  let roleUser = false;
  let view = false;

  if (useAuth2.user == null) {
    // console.log("No hay usuario");
  } else {
    if (user.email === work.userWork) {
      view = true;
    }
    const {
      user: { role },
    } = useAuth2;
    const isAdmin = role === "admin";
    roleUser = isAdmin;
  }

  function pathWork() {
    navigate(`/trabajos/${work._id}`)
  }

  const MessageButton = () =>
    setModalButtonsWork(
      modalButtonsWork === "work-buttons2" ? "work-buttons3" : "work-buttons2"
    );

  const handleSubmit = (event) => {
    event.preventDefault()
    const radio1 = inputRef1.current;
    const radio2 = inputRef2.current;
    const radio3 = inputRef3.current;
    const radio4 = inputRef4.current;
    const radio5 = inputRef5.current;

    let motiveReport = ""
    if (radio1.checked) {
      motiveReport = "Contenido Sexual"
    } else if (radio2.checked) {
      motiveReport = "Contenido engañoso"
    } else if (radio3.checked) {
      motiveReport = "Contenido humillante"
    } else if (radio4.checked) {
      motiveReport = "Trabajo inapropiado"
    } else if (radio5.checked) {
      motiveReport = "Otro motivo"
    } else {
      motiveReport = "No"
    }

    const hoy = new Date(Date.now());
    let now = (hoy.toISOString().split(".")[0]).replace("T", " ").slice(0, 16).replace(13, "1")
    now = now.replace(now[12], Number(now[12]))

    if (motiveReport !== "No") {
      reportController.createReport(accessToken, work._id, motiveReport, user.email, now)
      onOpenCloseModal()
    }
  }

  const addfav = async () => {
    const response = await favoriteController.createFavorite(accessToken, user.email, work._id)
    setHeart(response.status)
    setFavorite(response)
    return response
  }
  const deleteFavorite = async () => {
    const response = await favoriteController.deleteFavorites(accessToken, favorite._id)
    setHeart(false)
    window.location.reload(false)
    return response
  }

  useEffect(()=>{
    if(user!==null){
      if(work.userWork===user.email){
        setOptionsShow("optionsFavRep1")
      }
    }
  },[])

  return (
    <>
      <article className="card">
        {user === null ? "" : <div className={optionsShow}>
          <div className="optionsFavRepWorks">
            {view ? (
              <></>
            ) : (
              <>
                {roleUser ? (
                  <></>
                ) : (
                  <>
                    <ButtonForm
                      onClick={MessageButton}
                      styleButton="arrowMessage"
                      content={
                        <span className="material-symbols-outlined">
                          expand_more
                        </span>
                      }
                    />
                    <div className={modalButtonsWork}>
                      {
                        !favorite ?
                          <ButtonForm
                            onClick={addfav}
                            title={"Agregar favorito"}
                            styleButton="btnReportFav"
                            content={
                              <span className="material-symbols-outlined">heart_plus</span>
                            }
                          />
                          :
                          <ButtonForm
                            onClick={deleteFavorite}
                            title={"Eliminar favorito"}
                            styleButton="btnReportFav"
                            content={
                              <span className="material-symbols-outlined">heart_minus</span>
                            }
                          />
                      }
                      <ButtonForm
                        onClick={onOpenCloseModal}
                        title="Reportar"
                        styleButton="btnReportFav"
                        content={
                          <span className="material-symbols-outlined">report</span>
                        }
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <div onClick={pathWork} className="optionsFavRepWorks2"></div>
        </div>}
        <img  onClick={pathWork}
              className="temporary_text"
              src={
                work.imageWork
                  ? `${viewImage}`
                  : "https://th.bing.com/th/id/R.c3631c652abe1185b1874da24af0b7c7?rik=XBP%2fc%2fsPy7r3HQ&riu=http%3a%2f%2fpluspng.com%2fimg-png%2fpng-user-icon-circled-user-icon-2240.png&ehk=z4ciEVsNoCZtWiFvQQ0k4C3KTQ6wt%2biSysxPKZHGrCc%3d&risl=&pid=ImgRaw&r=0"
              }
              alt=""
            />
        <div className="card_content" onClick={pathWork}>
          <span className="card_title" title={work.name}>{work.name}</span>
          <span className="card_subtitle">
            <div id="cont-card-p">
              <p id="sub-description">Pago:</p>
              <p id="sub-description2">{work.price}</p>
            </div>
            <div id="cont-card-p">
              <p id="sub-description">Dirección:</p>
              <p id="sub-description2">{work.address}</p>
            </div>
            <div id="cont-card-p">
              <p id="sub-description">Fecha:</p>
              <p id="sub-description2">{work.date}</p>
            </div>
          </span>
          <p className="card_description">
            <div id="cont-card-description">
              <p id="sub-description">Descripción:</p>
              <p id="work-description">{work.description}</p>
            </div>
          </p>
        </div>
      </article>
      
      <form className={showModal} onSubmit={handleSubmit}>
        <h2>{`Reportar el trabajo ${work.name}`}</h2>
        <ReportOptions reference={inputRef1} content="Contenido Sexual" />
        <ReportOptions reference={inputRef2} content="Contenido engañoso" />
        <ReportOptions reference={inputRef3} content="Contenido humillante" />
        <ReportOptions reference={inputRef4} content="Trabajo inapropiado" />
        <ReportOptions reference={inputRef5} content="Otro motivo" />
        <div className="btnReportWork">
          <button type="button" onClick={onOpenCloseModal}>Cancelar</button>
          <button type="submit">Enviar reporte</button>
        </div>
      </form>
    </>
  );
}