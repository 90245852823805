import React from 'react'
import { Anclas } from "../../UI/Anclas/Anclas";

export const NoUserPostulate = () => {
  
    return ( 
    <section id='NoUserPersonalized'>
      <div id="ContainerIconAnchor">
        <span id="iconAnchor" className="material-symbols-outlined">group_add</span>
      </div>

      <h2>Haz seguimiento de tus trabajos</h2>
      <p>Debes acceder a tu cuenta para ver postulaciones.</p>
      <Anclas
        estilos="AnchorAccount"
        enlace="/auth"
        textoAncla={
          <div id="account">
            <span className="material-symbols-outlined">account_circle</span>
            Acceder
          </div>
        }
      />
    </section>
  )
}
