import React,{ useEffect, useState } from 'react'
import { ContenedorBuscador } from '../../UI/ContenedorBuscador/ContenedorBuscador'
import { ContenedorLogo } from '../../UI/ContenedorLogo/ContenedorLogo'
import { NavHeader } from '../../UI/NavHeader/NavHeader'
import { MenuLateral } from '../MenuLateral/MenuLateral'

export const HeaderHome = ({sendValue}) => {
  //Recibe el valor del input Hijo (contenedorBuscador)
  const handleMesajeCambiado = (nuevoMensaje)=>{
    //Envia el valor del input que recibio para enviarlo al contenedor padre
    sendValue(nuevoMensaje)
  }

  const [data,setData] = useState("")

  const IsOpen = (menuValue) =>{
    setData(menuValue)
  }

  return (
    <div className='Header'>
      <div id='contenedorHeader'>
          <MenuLateral datos={data}/>
          <ContenedorLogo Menu={IsOpen}/>
          <ContenedorBuscador onMensajeCambiado={handleMesajeCambiado}/>
          <NavHeader />
        </div>
    </div>
  )
}
