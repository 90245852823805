import React from 'react'
import { ContenidoCentral } from '../ContenidoCentral/ContenidoCentral'

export const Principal = (props) => {
  return (
    <section className='Principal'> 
        <ContenidoCentral datos={props.datos}/>
    </section>
  )
}
