import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { HeaderHome } from "../HeaderHome/HeaderHome";
import { Work, Postulate, Notification, Comment } from "../../../api";
import { TextWork } from "../../UI/TextWork/TextWork";
import { useAuth } from "../../../hooks";
import { Anclas } from "../../UI/Anclas/Anclas";
import { Confirm } from "semantic-ui-react";
import { BasicModal } from "../../UI/BasicModal/BasicModal";
import { WorkForm } from "../../UI/WorkForm/WorkForm";
import ButtonForm from "../../UI/ButtonForm/ButtonForm";
import { map, set } from "lodash";
import { ENV } from "../../../utils";
import { ModalConfirm } from "../../UI/ModalConfirm/ModalConfirm";

const workController = new Work();
const postulateController = new Postulate();
const notificationController = new Notification()
const commentController = new Comment()

export function WorksPath() {
  const navigate = useNavigate();
  const { user, accessToken } = useAuth();
  const useAuth2 = useAuth();
  const [postul, setPostul] = useState(false);
  const { path } = useParams();
  const [work, setWork] = useState(null);
  const [comments, setComments] = useState(null);
  const [promStarts, setPromStarts] = useState([]);

  const [modalButtonsWork, setModalButtonsWork] = useState("work-buttons2");
  const [showModal, setShowModal] = useState("modalProfile2");

  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [isDelete, setIsDelete] = useState(false);
  const [reload, onReload] = useState(false);
  const [postulates, setPostulates] = useState(null);
  const [viewImage, setViewImage] = useState("")
  const [otherViewImage, setOtherViewImage] = useState("")

  const countCalif = useRef()
  const promCalif = useRef()
  const startCalif = useRef()

  const onOpenCloseModal = () =>
    setShowModal(
      showModal === "modalProfile2" ? "modalProfile3" : "modalProfile2"
    );
  const MessageButton = () =>
    setModalButtonsWork(
      modalButtonsWork === "work-buttons2" ? "work-buttons3Path" : "work-buttons2"
    );
  const onOpenCloseConfirm = () => setShowConfirm((prevState) => !prevState);
  const modalWorkForm = () => onReload((prevState) => !prevState);

  let roleUser = false;
  let view = false;
  let prom = 0

  useEffect(() => {
    (async () => {
      if (user !== null) {
        setPostulates(null);
        const response = await postulateController.getPostulatesMe(
          accessToken,
          user
        );
        setPostulates(response);
      }
    })();
  }, []);

  let x = map(postulates, (post) => {
    if (work !== null) {
      if (post.id_Work === work._id) {
        return true;
      } else {
        return false;
      }
    }
  });

  if (work !== null) {
    if (useAuth2.user == null) {
      // console.log("No hay usuario");
    } else {
      if (user.email === work.userWork) {
        view = true;
      }
      const {
        user: { role },
      } = useAuth2;
      const isAdmin = role === "admin";
      roleUser = isAdmin;
    }
  }

  const [allImages, setAllImages] = useState([])

  useEffect(() => {
    if (work !== null) {
      let imagenes = work.imageWork
      let arrayImage = imagenes.split(" - ")
      arrayImage.pop()
      arrayImage.forEach(element => {
        setAllImages((newElement) => [...newElement, element])
      });
      setViewImage(arrayImage[0])

      arrayImage.shift()
      setOtherViewImage(arrayImage)
    }
  }, [work])

  useEffect(() => {
    (async () => {
      const response1 = await workController.getWorkPath(path);
      setWork(response1);
      const response2 = await commentController.getComments(response1.userWork)
      setComments(response2);
    })();
  }, []);

  useEffect(() => {
    setPostul(x.includes(true));
  }, [x]);

  const openUpdateUser = () => {
    onOpenCloseModal();
  };

  const openPostulateConfirm = () => {
    setIsDelete(false);
    setConfirmMessage(`¿Desea postularse a ${work.name}?`);
    onOpenCloseConfirm();
  };

  const openDeleteConfirm = () => {
    setIsDelete(true);
    setConfirmMessage(`Eliminar trabajo ${work.name}`);
    onOpenCloseConfirm();
  };

  const [showModalConfirm, setShowModalConfirm] = useState("ModalConfirmHide")

  const onPostulate = async () => {
    let y = [];
    let validation1 = 0;
    let validation2 = 0;
    let validation3 = 0;
    try {
      if (postulates !== []) {
        map(postulates, (post) => {
          let splitPost = post.date.split(" ")[1];
          let splitWork = work.date.split(" ")[1];
          if (splitPost !== undefined) {
            validation1 = Number(
              splitPost[0] + splitPost[1] + splitPost[3] + splitPost[4]
            );
            validation2 =
              Number(
                splitWork[0] + splitWork[1] + splitWork[3] + splitWork[4]
              ) - 200;
            validation3 =
              Number(
                splitWork[0] + splitWork[1] + splitWork[3] + splitWork[4]
              ) + 200;
          }
          if (
            post.date.split(" ")[0] === work.date.split(" ")[0] &&
            validation1 >= validation2 &&
            validation1 <= validation3
          ) {
            y.push("si");
          } else {
            y.push("no");
          }
        });
      }
      if (y.includes("si")) {
        y = [];
        let timeUpdate
        clearTimeout(timeUpdate)
        setShowModalConfirm("ModalConfirmHide")
        setShowModalConfirm("ModalConfirm")
        timeUpdate = setTimeout(() => {
          setShowModalConfirm("ModalConfirmHide")
        }, [3000])
      } else {
        await postulateController.createPostulates(accessToken, user, work);
        work.postulates = Number(work.postulates) - 1;
        await workController.updateWork(accessToken, work._id, work);

        const hoy = new Date(Date.now());
        let now = (hoy.toISOString().split(".")[0]).replace("T", " ").slice(0, 16).replace(13, "1")
        let arrayDeCaracteres = now.split('');
        arrayDeCaracteres[11] = String((Number(now[11] + now[12]) - 5))[0];
        arrayDeCaracteres[12] = String((Number(now[11] + now[12]) - 5))[1];
        let nuevoString = arrayDeCaracteres.join('');

        let notification = {
          title: "Usuario postulado",
          textNotification: `El usuario ${user.firstname} se ha postulado a tu trabajo ${work.name}`,
          date: `${nuevoString}`,
          id_Work: `${work._id}`,
          id_User: `${work.userWork}`
        }

        await notificationController.createNotification(accessToken, notification)
        window.location.reload(false);
      }
      // onReload()
      onOpenCloseConfirm();
    } catch (error) {
      console.error(error);
    }
  };

  const onDelete = async () => {
    try {
      await workController.deleteWork(accessToken, work._id);
      // onReload()
      onOpenCloseConfirm();
      navigate("/");
    } catch (error) {
      console.error(error);
    }
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  const handleImageClick = (index) => {
    clearInterval(intervalId);
    setCurrentImageIndex(index);

    const newIntervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % allImages.length);
    }, 2500);
    setIntervalId(newIntervalId);
  };

  useEffect(() => {
    const newIntervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % allImages.length);
    }, 2500);
    setIntervalId(newIntervalId);

    return () => clearInterval(newIntervalId);
  }, [allImages.length]);

  return (
    <div id="Chat">
      <ModalConfirm idModal={showModalConfirm} text="Ya te postulaste a otro trabajo" symbol={false}/>
      <HeaderHome></HeaderHome>
      <div className="containerChat">
        <div className="worksPath">
          {roleUser ? (
            <>
              <div id="DateOptionsWorks">
                <ButtonForm
                  onClick={MessageButton}
                  styleButton="arrowMessagePath"
                  content={
                    <span className="material-symbols-outlined">
                      expand_more
                    </span>
                  }
                />
              </div>
              <div className={modalButtonsWork}>
                <button
                  className="btnOptionWork"
                  onClick={openUpdateUser}
                >
                  Editar
                </button>
                <button
                  className="btnOptionWork"
                  onClick={openDeleteConfirm}
                >
                  Eliminar
                </button>
              </div>
            </>
          ) : (
            <>
              {view ? (
                <>
                  <div id="DateOptionsWorks">
                    <ButtonForm
                      onClick={MessageButton}
                      styleButton="arrowMessagePath"
                      content={
                        <span className="material-symbols-outlined">
                          expand_more
                        </span>
                      }
                    />
                  </div>
                  <div className={modalButtonsWork}>
                    <button
                      className="btnOptionWork"
                      onClick={openUpdateUser}
                    >
                      Editar
                    </button>
                    <Anclas
                      className="btnOptionWork"
                      estilos="buttonPostulates"
                      textoAncla="Postulados"
                      enlace={`/trabajo/postulados/${work._id}`}
                    ></Anclas>
                    <button
                      className="btnOptionWork"
                      onClick={openDeleteConfirm}
                    >
                      Eliminar
                    </button>
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          )}
          <div className="containerWork">
            <div className="containImgOptions">
              <img
                className="imgWork"
                src={
                  work
                    ? viewImage
                    : ""
                }
                alt=""
              />
            </div>
            <h2 className="titleWork">{work ? `${work.name}` : ""}</h2>
            <div className="workText">
              <TextWork
                className="textWork1"
                content={work ? `Ofertador` : ""}
              />
              <TextWork
                className="textWork2"
                content={work ? `: ${work.userWork}` : ""}
              />
            </div>
            {/* <div className="workText">
                <TextWork
                  className="textWork1"
                  content={work ? `Categoria` : ""}
                />
                <TextWork className="textWork2" content={work ? `: ???` : ""} />
              </div> */}
            <div className="workText">
              <TextWork
                className="textWork1"
                content={work ? `Direccion` : ""}
              />
              <TextWork
                className="textWork2"
                content={work ? `: ${work.address}` : ""}
              />
            </div>
            <div className="workText">
              <TextWork
                className="textWork1"
                content={work ? `Fin de la publicacion` : ""}
              />
              <TextWork
                className="textWork2"
                content={work ? `: ${work.date}` : ""}
              />
            </div>
            <div className="workText">
              <TextWork
                className="textWork1"
                content={work ? `Cantidad de postulados` : ""}
              />
              <TextWork
                className="textWork2"
                content={work ? `: ${work.postulates}` : ""}
              />
            </div>
            <div className="contDescription">
              <h2 className="titleWork">Descripción</h2>
              <TextWork
                className="textWork2"
                content={
                  work
                    ? `${work.description}`
                    : ""
                }
              />
            </div>
            <div className="workText">
              <div className="priceWork">PAGO:</div>
              <p className="numberPriceWork">
                {work ? `$${work.price}` : ""}
              </p>
            </div>
            {
              allImages.length >= 2 ?
                <>
                  <section className="PrincipalImage">
                    <img src={allImages[currentImageIndex]} alt="Principal">

                    </img>
                  </section>
                  <section className="slider">
                    <div className="slide-track">
                      {map(allImages, (img, index) => {
                        return (<div className="slide" onClick={() => handleImageClick(index)}>
                          <img
                            src={img}
                            alt=""
                            key={index} />
                        </div>)
                      })}
                    </div>
                  </section>
                </>
                : null
            }

            {user === null ? <></> :
              <>{roleUser ? "" :
                <>{view ?
                  "" : <>{postul ? (
                    <>
                      <h2 className="titleWork">Ya estas postulado</h2>
                    </>
                  ) : (
                    <>
                      {work ? (
                        work.postulates > 0 ? (
                          <div className="btnWorks">
                            <button
                              className="btnOptionWorkPost"
                              onClick={openPostulateConfirm}
                            >
                              Postularme
                            </button>
                          </div>
                        ) : (
                          <h2 className="titleWork">Alcanzo el maximo de postulaciones</h2>
                        )
                      ) : (
                        ""
                      )}
                    </>
                  )}</>}</>}</>}

            <h2 className="titleWork">Opiniones del ofertador</h2>
            <div className="containUserCalification">
              <div className="containerCalification">
                <p ref={promCalif} className=""></p>
                <div className="calification">
                  <p ref={startCalif}></p>
                  <p ref={countCalif}></p>
                </div>
              </div>
              {comments !== null ?
                map(comments, (comment, idx) => {
                  let starts = ""
                  let promStarts = ""
                  prom += Number(comment.starts)

                  for (let i = 1; i <= comment.starts; i++) {
                    starts += "⭐"
                  }

                  for (let i = 1; i <= Math.round(prom / (idx + 1)); i++) {
                    promStarts += "⭐"
                  }

                  startCalif.current.textContent = `${promStarts}`
                  promCalif.current.textContent = `${Math.round(prom / (idx + 1))}`
                  countCalif.current.textContent = `${(idx + 1)} calificaciones`

                  return (
                    <div key={idx} className="userCalification">
                      <div className="descCalificacion">
                        <p>{comment.userComment}</p>
                        <p>{starts}</p>
                      </div>
                      <p>{comment.comments}</p>
                    </div>
                  )
                })
                : ""}

            </div>
          </div>
        </div>
      </div>
      {work !== null ?
        <BasicModal show={showModal} title="Editar trabajo">
          <WorkForm
            close={onOpenCloseModal}
            work={work}
            onReload={modalWorkForm}
          />
        </BasicModal> : ""}
      <Confirm
        className="confirmModal"
        open={showConfirm}
        onCancel={onOpenCloseConfirm}
        onConfirm={isDelete ? onDelete : onPostulate}
        content={confirmMessage}
        size="mini"
      />
    </div>
  );
}