const Privacidad = [
    { titulo: 'Aceptación de los Términos y Condiciones', texto: 'Al utilizar este aplicativo, aceptas estar legalmente obligado por estos términos y condiciones, así como por nuestra política de privacidad. Si no estás de acuerdo con alguno de los términos o condiciones aquí establecidos, te rogamos que no utilices el aplicativo.' },

    { titulo: 'Uso del Aplicativo', texto: "2.1. El objetivo de este aplicativo es permitir a los usuarios publicar y buscar vacantes de trabajo. \n 2.2. Solo se permite el uso del aplicativo para fines legales y éticos. No se podrán publicar o difundir contenidos ilegales, difamatorios, obscenos, discriminatorios, violentos o que inciten al odio. \n 2.3. El contenido publicado en el aplicativo es responsabilidad exclusiva de quien lo ha publicado. No asumimos responsabilidad alguna por la exactitud, integridad o legalidad de dicho contenido." },

    { titulo: 'Registro y Cuenta de Usuario', texto: '3.1. Para utilizar todas las funcionalidades del aplicativo, es necesario registrarse y crear una cuenta de usuario. \n 3.2. Debes proporcionar información precisa y actualizada al registrarte. No puedes hacer uso de identidades falsas ni suplantar a otras personas o entidades. \n3.3. Eres responsable de mantener la confidencialidad de tu nombre de usuario y contraseña. Cualquier actividad que se realice en tu cuenta será de tu exclusiva responsabilidad.' },

    { titulo: 'Publicación de Vacantes', texto: '4.1. Al publicar una vacante en este aplicativo, garantizas que tienes la autoridad necesaria para ofrecer dicha posición laboral y que la información proporcionada es precisa y veraz. \n4.2. No permitimos la publicación de vacantes que infrinjan derechos de terceros o que promuevan trabajos ilegales o no éticos.\n4.3. Nos reservamos el derecho de eliminar o editar cualquier vacante publicada que consideremos inapropiada o que infrinja estos términos y condiciones.' }
]

export default Privacidad;