import React, { useState, useEffect } from 'react'
import { HeaderHome } from '../../../Layouts/HeaderHome/HeaderHome'
import { Report } from '../../../../api'
import { useAuth } from '../../../../hooks'
import { map } from 'lodash'
import { Anclas } from '../../../UI/Anclas/Anclas'

const reportController = new Report()

export function Reports() {
  const { accessToken } = useAuth()
  const [ reports, setReports ] = useState(null)

  useEffect(()=>{
    (async ()=>{
      const response = await reportController.getReports(accessToken)
      setReports(response)
    })()
  },[])

  return (
    <div id='reportsAdmin'>
        <HeaderHome></HeaderHome>
        <div className='containerReports'>     
            <div className='reports'>
                <h2>Reportes</h2>
                <div className='worksReports'>
                <div className='reportWork'>
                        <div className='containReport1'>
                          <p className='titleReport'>N°</p>
                        </div>
                        <div className='containReport'>
                          <p className='titleReport'>Id_Work</p>
                        </div>
                        <div className='containReport'>
                          <p className='titleReport'>Motivo</p>
                        </div>
                        <div className='containReport'>
                          <p className='titleReport'>Usuario Reporta</p>
                        </div>
                        <div className='containReport'>
                          <p className='titleReport'>Fecha_Hora</p>
                        </div>
                    </div>
                  {map(reports, (report, index)=>{
                    return (
                    <div key={index} className='reportWork'>
                        <div className='containReport1'>
                          <p>{index+1}</p>
                        </div>
                        <div className='containReport'>
                          <Anclas estilos="anclaReport" enlace={`/trabajos/${report.id_Work}`} textoAncla={report.id_Work}/>
                        </div>
                        <div className='containReport'>
                          <p>{report.motive}</p>
                        </div>
                        <div className='containReport'>
                          <Anclas estilos="anclaReport" enlace={`/user/${report.nameUser}`} textoAncla={report.nameUser}/>
                        </div>
                        <div className='containReport'>
                          <p>{report.date}</p>
                        </div>
                    </div>
                    )
                  })}
                </div>
            </div>
        </div>
    </div>
  )
}
