import React from "react";
import { Anclas } from "../../UI/Anclas/Anclas";

export const NoUserChat = () => {
  return (
    <section id="NoUserPersonalized">
      <div id="ContainerIconAnchor">
        <span id="iconAnchor" className="material-symbols-outlined">
          chat
        </span>
      </div>

      <h2>Interactua con las personas</h2>
      <p>Debes acceder a tu cuenta para acceder al chat</p>
      <Anclas
        estilos="AnchorAccount"
        enlace="/auth"
        textoAncla={
          <div id="account">
            <span className="material-symbols-outlined">account_circle</span>
            Acceder
          </div>
        }
      />
    </section>
  );
};
