import React, { useState, useEffect, useRef } from "react";
import ButtonForm from "../../UI/ButtonForm/ButtonForm";
import { Anclas } from "../../UI/Anclas/Anclas";
import InputRL from "../../UI/InputRL/InputRL";
import ImageBottomless from "../../UI/ImageBottomless/ImageBottomless";
import { Auth } from "../../../api";
import { useAuth } from "../../../hooks";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../../UI/Loader/Loader";
import { ENV } from "../../../utils";
import { ModalConfirm } from "../../UI/ModalConfirm/ModalConfirm"
import TerminosCondiciones from "../../../TerminosCondiciones";
import Privacidad from "../../../Privacidad";

const authController = new Auth();

export const LoginRegister = () => {
  const navigate = useNavigate()
  const { Route } = useParams();

  let validationRotate = localStorage.getItem("Validation");

  const [rotate, setRotate] = useState(validationRotate)
  const [state, setState] = useState(true);
  const [errorForm, setErrorForm] = useState(null)

  const btnRegister = () => {
    setRotate("container");
    localStorage.setItem("Validation", "container");
  };

  useEffect(() => {
    if (state) {
      setState(false)
      window.location.href = `${ENV.BASE_PATH}/auth?#`;
      setRotate(validationRotate)
    }
  }, []);

  const btnLogin = () => {
    setRotate("container right-panel-active");
    localStorage.setItem("Validation", "container right-panel-active");
  };

  // Login
  const [emailLogin, newEmailLogin] = useState("");
  const [passwordLogin, newPasswordLogin] = useState("");
  const [loaderLogin, setLoaderLogin] = useState(null);

  const { login } = useAuth();
  const [errorLogin, newErrorLogin] = useState(null);

  let userLogin = {
    email: emailLogin,
    password: passwordLogin,
  };

  async function sendFormLogin() {
    try {
      if (userLogin.email === "") {
        newErrorLogin('Llene el campo "E-mail"');
      } else if (userLogin.password === "") {
        newErrorLogin('Llene el campo "contraseña"');
      } else {
        newErrorLogin(null);
        setLoaderLogin(true);
        const response = await authController.login(userLogin);

        authController.setAccessToken(response.access);
        authController.setRefreshToken(response.refresh);

        login(response.access);
        navigate("/");
        window.location.reload(false);
      }
    } catch (error) {
      setLoaderLogin(null);
      newErrorLogin(`${error.msg}`);
    }
  }

  //Register

  const [name, newName] = useState("");
  const [lastName, newLastName] = useState("");
  const [email, newEmail] = useState("");
  const [password, newPassword] = useState("");

  const [loaderRegister, setLoaderRegister] = useState(null);
  const [errorRegister, newErrorRegister] = useState(null);

  let userRegister = {
    firstname: name,
    lastname: lastName,
    email: email,
    password: password,
  };

  const inputRef = useRef(null);
  const inputCheck = inputRef.current

  async function sendFormRegister() {
    try {
      if (userRegister.firstname === "") {
        newErrorRegister('Llene el campo "Nombre"');
      } else if (userRegister.lastname === "") {
        newErrorRegister('Llene el campo "Apellido"');
      } else if (userRegister.email === "") {
        newErrorRegister('Llene el campo "E-mail"');
      } else if (!userRegister.email.includes("@" && ".")) {
        newErrorRegister("El campo E-mail debe incluir '@' y '.'");
      } else if (userRegister.password === "") {
        newErrorRegister('Llene el campo "Contraseña"');
      } else if (inputCheck.checked === false) {
        newErrorRegister('Debe Aceptar Términos y condiciones"');
      } else {
        newErrorRegister(null);
        setLoaderRegister(true);
        await authController.register(userRegister);
        localStorage.setItem("Validation", "container");
        setErrorForm("Usuario registrado")
        setTimeout(() => {
          window.location.reload();
        }, [2000])
        navigate("/auth");
      }
    } catch (error) {
      setLoaderRegister(null);
      newErrorRegister(`${error.msg}`);
    }
  }

  function pathWork() {
    navigate("/");
  }

  const [showModalConfirmRegister, setShowModalConfirmRegister] = useState("ModalConfirmHide")
  const [showModalConfirmLogin, setShowModalConfirmLogin] = useState("ModalConfirmHide")

  useEffect(() => {
    if (errorRegister != null) {
      let stopRegister
      clearTimeout(stopRegister)
      setShowModalConfirmRegister("ModalConfirmHide")
      setShowModalConfirmRegister("ModalConfirm")
      setTimeout(() => {
        stopRegister = setShowModalConfirmRegister("ModalConfirmHide")
      }, [2500])
    }
  }, [errorRegister])

  useEffect(() => {
    if (errorLogin != null) {
      let stopLogin
      clearTimeout(stopLogin)
      setShowModalConfirmLogin("ModalConfirmHide")
      setShowModalConfirmLogin("ModalConfirm")
      setTimeout(() => {
        stopLogin = setShowModalConfirmLogin("ModalConfirmHide")
      }, [2500])
    }
  }, [errorLogin])

  const [showModalConfirm, setShowModalConfirm] = useState("ModalConfirmHide")

  useEffect(() => {
    if (errorForm != null) {
      let stopLogin
      clearTimeout(stopLogin)
      setShowModalConfirm("ModalConfirmHide")
      setShowModalConfirm("ModalConfirm")
      setTimeout(() => {
        stopLogin = setShowModalConfirm("ModalConfirmHide")
      }, [5000])
    }
  }, [errorForm])

  const [ModalTermsValue, setModalTermsValue] = useState("ModalTermsHide")
  const [TitleModalTerms, setTitleModalTerms] = useState(null)

  function closeModalTerms() {
    ModalTermsValue === "ModalTermsHide" ? setModalTermsValue("ModalTerms") : setModalTermsValue("ModalTermsHide")
  }

  function ModalTerms(value) {
    ModalTermsValue === "ModalTermsHide" ? setModalTermsValue("ModalTerms") : setModalTermsValue("ModalTermsHide")
    let newValue = value
    newValue === 1 ? setTitleModalTerms("Términos y condiciones de uso del sitio") : setTitleModalTerms("Privacidad")
  }

  return (
    // REGISTER
    <div className={rotate} id="container">
      <ModalConfirm idModal={showModalConfirmRegister} text={errorRegister} symbol={false} />
      <ModalConfirm idModal={showModalConfirmLogin} text={errorLogin} symbol={false} />
      <ModalConfirm idModal={showModalConfirm} text="Usuario registrado" symbol={true} />
      <section className={ModalTermsValue}>
        <div className="ShowcontainerTerms">
          <div className="ContainTitleTerms">
            <h1>{TitleModalTerms}</h1>
            <button onClick={closeModalTerms} className="btnCloseTerms">
              <span class="material-symbols-outlined">
                close
              </span>
            </button>
          </div>
          {
            TitleModalTerms === "Términos y condiciones de uso del sitio"

              ? TerminosCondiciones.map((item, index) => (

                <div key={index} className="ContainerEachTerms" style={{ marginTop: index === 0 ? '10vh' : null }}>
                  <h2>{`${index + 1} - ${item.titulo}`}</h2>
                  <p>{item.texto}</p>
                </div>
              ))

              : Privacidad.map((item, index) => (

                <div key={index} className="ContainerEachTerms" style={{ marginTop: index === 0 ? '10vh' : null }}>
                  <h2>{`${index + 1} - ${item.titulo}`}</h2>
                  <p>{item.texto}</p>
                </div>
              ))
          }
        </div>
      </section>

      <div className="form-container register-container">
        <div className="logoCompany">
          <div className="imgLogo">
            <ImageBottomless click={pathWork} />
          </div>
        </div>
        <form action="#" className="formLR" noValidate>
          <h1 className="titleLR">Registrate</h1>
          <InputRL
            value={name}
            type="text"
            style2="inputLR"
            textSpan="Nombre"
            onChange={(e) => {
              newName(e.target.value);
            }}
          />
          <InputRL
            value={lastName}
            type="text"
            style2="inputLR"
            textSpan="Apellido"
            onChange={(e) => {
              newLastName(e.target.value);
            }}
          />
          <InputRL
            value={email}
            type="text"
            style2="inputLR"
            textSpan="E-mail"
            onChange={(e) => {
              newEmail(e.target.value);
            }}
          />
          <InputRL
            value={password}
            type="password"
            style2="inputLR"
            textSpan="Contraseña"
            onChange={(e) => {
              newPassword(e.target.value);
            }}
          />
          <ButtonForm
            onClick={sendFormRegister}
            styleButton="buttonLR"
            content="Registrarse"
          />
          <div id="loadLogin">
            {loaderRegister != null ? <Loader /> : <div className="containerTerms">
              <input ref={inputRef} type="checkbox" name="" id="" />
              <p>Autorizo el uso de mis datos de acuerdo a la
                <Anclas
                  estilos="AnchorRegister"
                  textoAncla=" Declaración de Privacidad "
                  eventoAncla={() => ModalTerms(2)}
                ></Anclas>
                y acepto los
                <Anclas
                  estilos="AnchorRegister"
                  textoAncla=" Términos y condiciones."
                  eventoAncla={() => ModalTerms(1)}
                ></Anclas></p>
            </div>}
          </div>
        </form>
      </div>

      {/* LOGIN */}
      <div className="form-container login-container">
        <div className="logoCompany">
          <div className="imgLogo">
            <ImageBottomless click={pathWork} />
          </div>
          {/* <TitleWorkServices /> */}
        </div>
        <form action="#" className="formLR" noValidate>
          <div className="containLogin">
            <h1 className="titleLR">Iniciar sesion</h1>
            <InputRL
              value={emailLogin}
              type="text"
              style2="inputEmailLR"
              textSpan="E-mail"
              onChange={(e) => {
                newEmailLogin(e.target.value);
              }}
            />
            <InputRL
              value={passwordLogin}
              type="password"
              style2="inputEmailLR"
              textSpan="Contraseña"
              onChange={(e) => {
                newPasswordLogin(e.target.value);
              }}
            />
            <ButtonForm
              onClick={sendFormLogin}
              styleButton="buttonLR"
              content="Ingresar"
            />
          </div>
          <div id="loadLogin">
            {loaderLogin != null && <Loader />}
          </div>
          <div className="informationLogin">
            <button className="anchorLogin" onClick={btnLogin}>
              Crear Cuenta
            </button>
            <Anclas
              estilos="anchorLogin"
              textoAncla="Recuperar contraseña"
              enlace="/auth/recoverPassword"
            />
          </div>
        </form>
      </div>

      <div className="overlay-container">
        <div className="overlay">
          <div className="overlay-panel overlay-left">
            <h1 className="titleGhost">Descubre tu camino ahora</h1>
            <p className="textLR">Si tienes una cuenta, inicia sesión aquí</p>
            <button className="ghost" onClick={btnRegister}>
              Iniciar Sesión
            </button>
          </div>
          <div className="overlay-panel overlay-right">
            <h1 className="titleGhost">Hola de nuevo</h1>
            <p className="textLR">
              Si aún no tienes cuenta, Únete a nosotros y comienza tu viaje
            </p>
            <button className="ghost" onClick={btnLogin}>
              Registrarse
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
