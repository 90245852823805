import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Anclas } from "../Anclas/Anclas";
import { useAuth } from "../../../hooks";
import { Button } from "semantic-ui-react";
import { BasicModal } from "../../UI/BasicModal/BasicModal";
import { WorkForm } from "../../UI/WorkForm/WorkForm";
import { Notification } from "../../../api";
import { functions, map } from "lodash";
import { ModalConfirm } from "../ModalConfirm/ModalConfirm";

const notificationController = new Notification()

export const NavHeader = () => {
  const [ showModal, setShowModal ] = useState("modalProfile2")
  const [ showModalNotification, setShowModalNotification ] = useState("modalNotification")
  const [ reload, setReload ] = useState(false)

  // const [ notifications, setNotifications ] = useState(null)
  const [ notifications, setNotifications ] = useState([])

  const navigate = useNavigate();
  const { user, accessToken } = useAuth();
  const onOpenCloseModal = () => {
    navigate("/");
    setShowModal(showModal === "modalProfile2" ? "modalProfile3" : "modalProfile2");
    setOverlayMenu(showModal === "modalProfile2" ? "ModalContainer" : "ModalContainerHide")
  };
  const onReload = () => setReload((prevState) => !prevState);

  function viewNotification () {
    setShowModalNotification(showModalNotification === "modalNotification" ? "modalNotification1" : "modalNotification");

    showModalNotification === "modalNotification1" ? setShowNot(false) : setShowNot(null)
  }

  useEffect(()=>{
    (async()=>{
      if(user!==null){
        const notification = await notificationController.getNotifications(accessToken, user.email)
        notification.sort((a, b) => a.date - b.date)
        setNotifications(notification)
      }
    })()
  },[])

  function pathNotification(notification){
    if(notification.title === "Usuario postulado"){
      navigate(`/trabajo/postulados/${notification.id_Work}`)
    }

    if(notification.title === "Has sido seleccionado"){
      navigate(`/chat`)
    }
  }

  const [showModalConfirm,setShowModalConfirm] = useState("ModalConfirmHide")

  const ModalConfirmValue = (value) =>{
    if(value===true){
      let timeUpdate
      clearTimeout(timeUpdate)
      setShowModalConfirm("ModalConfirmHide")
      setShowModalConfirm("ModalConfirm")
      timeUpdate = setTimeout(()=>{
       setShowModalConfirm("ModalConfirmHide")
      },[3000])
    }
  }

  const [ConfirmImg,setConfirmImg] = useState("ModalConfirmHide")
  const [textModal,setTextModal] = useState(null)
  const [symbol,setSymbol] = useState(false)

  const validateImg = (value) =>{
    if(value===true){
      let timeUpdate
      clearTimeout(timeUpdate)
      setConfirmImg("ModalConfirmHide")
      setTextModal("Imagenes Guardadas")
      setSymbol(true)
      setConfirmImg("ModalConfirm")
      timeUpdate = setTimeout(()=>{
       setConfirmImg("ModalConfirmHide")
      },[3000])
    }
    else{
      let timeUpdate
      clearTimeout(timeUpdate)
      setConfirmImg("ModalConfirmHide")
      setTextModal('Limite de imagenes "6"')
      setSymbol(false)
      setConfirmImg("ModalConfirm")
      timeUpdate = setTimeout(()=>{
       setConfirmImg("ModalConfirmHide")
      },[3000])
    }
  }

  function validationLogin(){
    localStorage.setItem("Validation", "container");
  }
  function validationRegister(){
    localStorage.setItem("Validation", "container right-panel-active");
  }

  const [overlayMenu,setOverlayMenu] = useState("ModalContainerHide")

  const [showNot,setShowNot] = useState(true)

  return (
    <div id="NavHeader">
    <ModalConfirm idModal={showModalConfirm} text="Trabajo creado" symbol={true}/>
    <ModalConfirm idModal={ConfirmImg} text={textModal} symbol={symbol} />
      <div id="containers">
        {!user ? (
          <>
            <div id="logos" onClick={validationRegister}>
              <Anclas
                estilos="Ancla AnclaNav"
                textoAncla={
                  <>
                  <span className="material-symbols-outlined" id="iconHeader">
                    person_add
                  </span>
                  <p>Registro</p>
                  </>
                }
                enlace="/auth?#"
              />
            </div>

            <div id="logos" onClick={validationLogin}>
              <Anclas
                estilos="Ancla AnclaNav"
                textoAncla={
                  <>
                  <span className="material-symbols-outlined" id="iconHeader">
                    person
                  </span>
                  <p>Login</p></>
                  
                }
                enlace="/auth?#"
              />
            </div>
          </>
        ) : (
          <>
            <div id="logos">
              <Button
                className="addWork"
                primary
                onClick={onOpenCloseModal}
                title="Crear Trabajo"
              >
                <span className="material-symbols-outlined" id="iconHeader">medical_services</span>
              </Button>
            </div>

            <div className={showModalNotification}>
              <h2 className="titleNotification">Notificaciones</h2>

              {notifications.length !== 0 ? 
              notifications.slice().reverse().map((notification, index)=>{
                function sendNotification(){
                  pathNotification(notification)
                }

                return (
                  <div onClick={sendNotification} className="mapNotification" key={index}>
                    <h2>{notification.title}</h2>
                    <p>{notification.textNotification}</p>
                  </div>
                )
              }) : <div className="modalNotificationEmpty">No hay Notificaciones</div>
              }
            </div>

            <div id="logos" className="AnchorNotification" onClick={viewNotification}>
              {
                showNot === true ? notifications.length !== 0 
                ? <div className="cantNotifications">{notifications.length}</div> : null : null
                
              }
  
              {/* Notificaciones */}
              <Anclas
                estilos="Ancla"
                textoAncla={
                  <span className="material-symbols-outlined" id="iconHeader">
                    notifications
                  </span>
                }
                title="Notificaciones"
              />
            </div>

            <div id="logos">
              {/* Usuario */}
              <Anclas
                estilos="Ancla"
                textoAncla={
                  <span className="material-symbols-outlined" id="iconHeader">
                    account_circle
                  </span>
                }
                enlace="/profile"
                title="Perfil"
              />
            </div>
          </>
        )}
      </div>
      <section className={overlayMenu}>
      <button id="btnCloseMenu" onClick={onOpenCloseModal}></button>
      <BasicModal show={showModal} title="Publicar trabajo">
        <WorkForm valueModalConfirm={ModalConfirmValue} validateImg={validateImg} close={onOpenCloseModal} onReload={onReload} />
      </BasicModal>
      </section>
      
    </div>
  );
};
