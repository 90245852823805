import React, { useState, useEffect } from "react";
import { Button, Confirm } from "semantic-ui-react";
import ButtonForm from "../ButtonForm/ButtonForm";
import { ENV } from "../../../utils";
import { Favorite, Work } from "../../../api";
import { useAuth } from "../../../hooks";
import { map } from "lodash";

const favoritecontroller = new Favorite();
const workController = new Work()

export function FavoriteItem(props) {
  const { work, onReload, favorite } = props;
  const { user, accessToken } = useAuth();
  const [favorit, setFavorit] = useState(false);
  const useAuth2 = useAuth();
  const [showModal, setShowModal] = useState("modalReport");
  const [modalButtonsWork, setModalButtonsWork] = useState("work-buttons2");
  const [viewImage, setViewImage] = useState("")

  let roleUser = false;
  let view = false;

  if (useAuth2.user == null) {
    // console.log("No hay usuario");
  } else {
    if (user.email === work.userWork) {
      view = true;
    }
    const {
      user: { role },
    } = useAuth2;
    const isAdmin = role === "admin";
    roleUser = isAdmin;
  }

  let x = map(favorite, (post) => {
    return map(post, (param) => {
      if (param.idWork === work._id) {
        return true;
      } else {
        return false;
      }
    })
  });

  useEffect(() => {
    map(x, (includeWork) => {
      setFavorit(includeWork.includes(true));
    })
  }, [x]);

  const MessageButton = () =>
    setModalButtonsWork(
      modalButtonsWork === "work-buttons2" ? "work-buttons3" : "work-buttons2"
    );

  const onOpenCloseModal = () => setShowModal(showModal === "modalReport" ? "modalReport2" : "modalReport");

  const onDeleteFav = async () => {
    try {
      map(favorite, async (post) => {
        map(post, (param) => {
          if (param.idWork === work._id) {
            favoritecontroller.deleteFavorites(accessToken, param._id)
          }
        })
      })
      window.location.href = `${ENV.BASE_PATH}/favoritos`
      onReload();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    let imagenes = work.imageWork
    let arrayImage = imagenes.split(" - ")

    arrayImage.pop()
    setViewImage(arrayImage[0])
  }, [])

  function pathWork() {
    window.location.href = `${ENV.BASE_PATH}/trabajos/${work._id}`;
  }

  return (
    <>
      {favorit ? (
        <>
          <article className="card">
            {user === null ? "" : <div className="optionsFavRep">
              <div className="optionsFavRepWorks">
                {view ? (
                  <></>
                ) : (
                  <>
                    {roleUser ? (
                      <></>
                    ) : (
                      <>
                        <ButtonForm
                          onClick={MessageButton}
                          styleButton="arrowMessage"
                          content={
                            <span className="material-symbols-outlined">
                              expand_more
                            </span>
                          }
                        />
                        <div className={modalButtonsWork}>
                          <ButtonForm
                            onClick={onDeleteFav}
                            title="Deshacer Favotito"
                            styleButton="btnReportFav"
                            content={
                              <span className="material-symbols-outlined">heart_minus</span>
                            }
                          />
                          <ButtonForm
                            onClick={onOpenCloseModal}
                            title="Reportar"
                            styleButton="btnReportFav"
                            content={
                              <span className="material-symbols-outlined">report</span>
                            }
                          />
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
              <div onClick={pathWork} className="optionsFavRepWorks2"></div>
            </div>}
            <img onClick={pathWork}
              className="temporary_text"
              src={
                work.imageWork
                  ? `${viewImage}`
                  : "https://th.bing.com/th/id/R.c3631c652abe1185b1874da24af0b7c7?rik=XBP%2fc%2fsPy7r3HQ&riu=http%3a%2f%2fpluspng.com%2fimg-png%2fpng-user-icon-circled-user-icon-2240.png&ehk=z4ciEVsNoCZtWiFvQQ0k4C3KTQ6wt%2biSysxPKZHGrCc%3d&risl=&pid=ImgRaw&r=0"
              }
              alt=""
            />
            <div className="card_content" onClick={pathWork}>
              <span className="card_title" title={work.name}>{work.name}</span>
              <span className="card_subtitle">
                <div id="cont-card-p">
                  <p id="sub-description">Pago:</p>
                  <p id="sub-description2">{work.price}</p>
                </div>
                <div id="cont-card-p">
                  <p id="sub-description">Dirección:</p>
                  <p id="sub-description2">{work.address}</p>
                </div>
                <div id="cont-card-p">
                  <p id="sub-description">Fecha:</p>
                  <p id="sub-description2">{work.date}</p>
                </div>
              </span>
              <p className="card_description">
                <div id="cont-card-description">
                  <p id="sub-description">Descripción:</p>
                  <p id="work-description">{work.description}</p>
                </div>
              </p>
            </div>
          </article>
        </>
      ) : (
        <></>
      )}
      {/* <Confirm
            className="confirmModal"
            open={showConfirm}
            onCancel={onOpenCloseConfirm}
            onConfirm={onDeletePostulation}
            content={confirmMessage}
            size="mini"
        /> */}
    </>
  );
}
