import React,{useEffect, useState} from "react";
import { Anclas } from "../../UI/Anclas/Anclas";
import { Barras } from "../../Iconos/Barras/Barras";
import { useAuth } from "../../../hooks";
import { Button, Icon } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { ENV } from "../../../utils";

export const MenuLateral = (props) => {
  const dataMenu = props.datos;
  const navigate = useNavigate();
  const { user } = useAuth();

  const useAuth2 = useAuth();
  let roleUser = false;

  if (useAuth2.user == null) {
    // console.log("No hay usuario");
  } else {
    const { user: { role }} = useAuth2;
    const isAdmin = role === "admin";
    roleUser = isAdmin;
  }

  const [MenuOpen,setMenuOpen] = useState(dataMenu)

  const [overlayMenu,setOverlayMenu] = useState("containerMenuOculto")
  const [valueMenu,setValueMenu] = useState("MenuLateralEstaticoOculto")

  function menuAction() {
    if(MenuOpen===true){
      setOverlayMenu("containerMenu")
      setValueMenu("MenuLateralEstatico")
    }else{
      setOverlayMenu("containerMenuOculto")
      setValueMenu("MenuLateralEstaticoOculto")
    }
  }

  useEffect(()=>{
    MenuOpen === true ? setMenuOpen(false) : setMenuOpen(true)
    menuAction()
  },[dataMenu])

  useEffect(()=>{
    menuAction()
  },[MenuOpen])

  const closeMenu = ()=>{
    setMenuOpen(false)
  }

  return (
    <section className={overlayMenu}>
      <button id="btnCloseMenu"onClick={closeMenu}></button>
      <section className={valueMenu}>
        <div className="containerHome">
          <Anclas estilos="AnclaMenuHome" enlace="/" textoAncla={<><Barras /><p>Inicio</p></>} />
        </div>
        <div className="ContainerAnclas">
          {!user ? (
            <>
              <Anclas
                eventoAncla={closeMenu}
                estilos="AnclaMenu"
                enlace="/NoUserAnchor/1"
                textoAncla={<><span id="iconMenu" className="material-symbols-outlined">group_add</span><p>Postulaciones</p></>}
              />
              <Anclas
                eventoAncla={closeMenu}
                estilos="AnclaMenu"
                enlace="/NoUserAnchor/2"
                textoAncla={<><span id="iconMenu" className="material-symbols-outlined">chat</span><p>Chat</p></>}
              />
              <Anclas
                eventoAncla={closeMenu}
                estilos="AnclaMenu"
                enlace="/NoUserAnchor/3"
                textoAncla={<><span id="iconMenu" className="material-symbols-outlined">history</span><p>Historial</p></>}
              />
              <Anclas
                eventoAncla={closeMenu}
                estilos="AnclaMenu"
                enlace="/NoUserAnchor/4"
                textoAncla={<><span id="iconMenu" className="material-symbols-outlined">hotel_class</span><p>Favoritos</p></>}
              />
            </>
          ) : (
            <>
              {!roleUser ? (
                <>
                  <nav className="MenuAnclas">
                    <Anclas
                      eventoAncla={closeMenu}
                      estilos="AnclaMenu"
                      enlace="/postulaciones"
                      textoAncla={<><span id="iconMenu" className="material-symbols-outlined">group_add</span><p>Postulaciones</p></>}
                    />
                    <Anclas
                      eventoAncla={closeMenu}
                      estilos="AnclaMenu"
                      enlace="/chat"
                      textoAncla={<><span id="iconMenu" className="material-symbols-outlined">chat</span><p>Chat</p></>}
                    />
                    <Anclas
                      eventoAncla={closeMenu}
                      estilos="AnclaMenu"
                      enlace="/historialTrabajos"
                      textoAncla={<><span id="iconMenu" className="material-symbols-outlined">history</span><p>Historial</p></>}
                    />
                    <Anclas
                      eventoAncla={closeMenu}
                      estilos="AnclaMenu"
                      enlace="/favoritos"
                      textoAncla={<><span id="iconMenu" className="material-symbols-outlined">hotel_class</span><p>Favoritos</p></>}
                    />
                  </nav>
                </>
              ) : (
                <>
                  <nav className="MenuAnclas">
                    <Anclas
                      eventoAncla={closeMenu}
                      estilos="AnclaMenu"
                      enlace="/administrarTrabajos"
                      textoAncla={<><span id="iconMenu" className="material-symbols-outlined">work</span><p>Trabajos</p></>}
                    />
                    <Anclas
                      eventoAncla={closeMenu}
                      estilos="AnclaMenu"
                      enlace="/administrarUsuarios"
                      textoAncla={<><span id="iconMenu" className="material-symbols-outlined">group</span><p>Usuarios</p></>}
                    />
                    <Anclas
                      eventoAncla={closeMenu}
                      estilos="AnclaMenu"
                      enlace="/reportes"
                      textoAncla={<><span id="iconMenu" className="material-symbols-outlined">report</span><p>Reportes</p></>}
                    />
                  </nav>
                </>
              )}
            </>
          )}
        </div>
      </section>
    </section>
  );
};
