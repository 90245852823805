import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Form } from "semantic-ui-react"
import { initialValues, validationSchema } from "../ValuesForm/ValuesForm"
import { useFormik } from "formik"
import { useDropzone } from "react-dropzone"
import { User } from '../../../api'
import { useAuth } from '../../../hooks'

const userController = new User()

export function UserForm(props) {
  const { close, onReload, user, valueModalConfirm } = props
  const { accessToken } = useAuth()
  const [img, setImg] = useState("")
  const [loading, setLoading] = useState(false)
  const [valueImg, setValueImg] = useState(null)
  const [nameImg, setNameImg] = useState(null)
  const imageUser = useRef(null)

  const formik = useFormik({
    initialValues: initialValues(user),
    validationSchema: validationSchema(user),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        const filesImage = imageUser.current.files
        const data = new FormData()
        data.append("file", filesImage[0])
        data.append("upload_preset", "ImgUser")
        setLoading(true)
        const res = await fetch(
          "https://api.cloudinary.com/v1_1/dpuasqaeb/image/upload",
          {
            method: "POST",
            body: data
          }
        )
        const file = await res.json();
        setImg(file.secure_url)
        setLoading(false)
        formValue.avatar = file.secure_url
        await userController.updateUser(accessToken, user._id, formValue)
        onReload()
        close()
        valueModalConfirm(true)
      } catch (error) {
        console.error(error);
      }
    }
  })

  function closeForm() {
    onReload()
    close()
  }

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0]
    formik.setFieldValue("avatar", URL.createObjectURL(file))
    formik.setFieldValue("fileAvatar", file)
  })

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    onDrop,
  })

  const changeImg = (event) => {
    const file = event.target.files[0];
    if (file === undefined) {
      setValueImg(null)
    } else {
      setNameImg(file.name)
      setValueImg(file)
      const reader = new FileReader();
      reader.onload = (e) => {
        const url = e.target.result;
        setValueImg(url);
      }
      reader.readAsDataURL(file);
    }
  }

  return (
    <Form className='user-form' onSubmit={formik.handleSubmit}>
      <div id='inputContainerUser'>
        <input ref={imageUser} type="file" name="file" id="inputImage" onChange={changeImg} />
        <label id='input-file_btn'>{valueImg != null? <div id='textFile'>${nameImg}</div> : <div id='textFileEmpty'><span className="material-symbols-outlined">
          upload
        </span>Seleccionar archivo</div>}</label>
      </div>
      {valueImg != null? <img id='ImgUserSelected' src={valueImg} alt="vista previa"></img> : null}
      <Form.Input name="firstname" placeholder="Nombre" onChange={formik.handleChange}
        value={formik.values.firstname || ''} error={formik.errors.firstname}
      />
      <Form.Input name="lastname" placeholder="Apellidos" onChange={formik.handleChange}
        value={formik.values.lastname || ''} error={formik.errors.lastname}
      />
      <Form.Input name="email" placeholder="Correo electronico" onChange={formik.handleChange}
        value={formik.values.email || ''} error={formik.errors.email}
      />
      <Form.Input type='password' name="password" placeholder="Contraseña"
        onChange={formik.handleChange} value={formik.values.password || ''} error={formik.errors.password}
      />
      <div className='formDivButton'>
        <Form.Button type='button' primary fluid onClick={closeForm} >
          Cancelar
        </Form.Button>
        <Form.Button type='submit' primary fluid loading={formik.isSubmitting}>
          Actualizar
        </Form.Button>
      </div>
    </Form>
  )
}
