import React, {useState, useEffect} from 'react'
import { HeaderHome } from '../../Layouts/HeaderHome/HeaderHome'
import { ListFavorites } from '../../UI//ListFavorites/ListFavorites'

export const Favorites = () => {
  const [ reload, setReload ] = useState(false)
  const onReload = () => setReload((prevState) => !prevState)

  const [data, setData] = useState("");

  const catchValue = (valueHeaderHome) => {
    setData(valueHeaderHome);
  };
    
  return (
    <div className='viewPostulation'>
      <HeaderHome sendValue={catchValue}></HeaderHome>
      <div className="containPostulation"> 
          
        <div className='peoplePostulated'>
          <h2 className='favoritesWork'>Favoritos</h2>
          <div className='containCardPost'>
            <ListFavorites datos={data} worksActive={true} reload={reload} onReload={onReload} />
          </div>
        </div>
      </div>
    </div>
  )
}
