import { ENV } from "../utils"

export class Comment {
    baseApi = ENV.BASE_API;

    async createComment(accessToken, userComment, userWork, starts, comments) {
        try {
            const url = `${this.baseApi}/${ENV.API_ROUTES.COMMENTS}`
            const params = {
                method: "POST",
                headers: {
                    Authorization: `Token ${accessToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    userComment: userComment,
                    userWork: userWork,
                    starts: starts,
                    comments: comments
                }),
            }

            const response = await fetch(url, params)
            const result = await response.json()

            if(response.status !== 200) throw result;
            return result

        } catch (error) {
            throw error
        }
    }

    async getComments(idUser){
        try {
            const url = `${this.baseApi}/${ENV.API_ROUTES.COMMENTS}/${idUser}`

            const response = await fetch(url)
            const result = await response.json()

            if(response.status !== 200) throw result;
            return result

        } catch (error) {
            throw error
        }
    }

    async deleteComments(accessToken, idMessage) {
        try {
            const url = `${this.baseApi}/${ENV.API_ROUTES.CHAT}/${idMessage}`
            const params = {
                method: "DELETE",
                headers: {
                    Authorization: `Token ${accessToken}`
                },
            }

            const response = await fetch(url, params)
            const result = await response.json()

            if(response.status !== 200) throw result;
            return result
        } catch (error) {
            throw error
        }
    }
}