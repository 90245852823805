import React from 'react'
import { Loader } from 'semantic-ui-react'
import { size, map } from "lodash"
import { WorksDoneItem } from '../WorksDoneItem/WorksDoneItem'
import { Anclas } from '../Anclas/Anclas'

export function ListWorksDone(props) {
    const { works, onReload } = props
    if(!works) return <Loader active inline="centered" />
    if(size(works) === 0) return (<>
      <section id='NoUserPersonalizedHistory'>
        <div id="ContainerIconAnchor">
        <span id="iconAnchor" className="material-symbols-outlined">playlist_add</span>
        </div>
  
        <h2>No hay trabajos aqui</h2>
        <p>Debes crear publicaciones para verlas aqui</p>
      </section></>)

  return map(works, (work) => <WorksDoneItem key={work._id} work={work} onReload={onReload} />)
}