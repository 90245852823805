import { ENV } from "../utils"

export class Report {
    baseApi = ENV.BASE_API;

    async createReport(accessToken, id_Work, motive, nameUser, date) {
        try {
            const url = `${this.baseApi}/${ENV.API_ROUTES.REPORT}`
            const params = {
                method: "POST",
                headers: {
                    Authorization: `Token ${accessToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    id_Work: id_Work,
                    motive: motive,
                    nameUser: nameUser,
                    date: date
                }),
            }

            const response = await fetch(url, params)
            const result = await response.json()

            if(response.status !== 200) throw result;
            return result

        } catch (error) {
            throw error
        }
    }

    async getReports(accessToken){
        try {
            const url = `${this.baseApi}/${ENV.API_ROUTES.REPORTS}`
            const params = {
                headers: {
                    Authorization: `Token ${accessToken}`
                },
            }

            const response = await fetch(url, params)
            const result = await response.json()

            if(response.status !== 200) throw result;
            return result

        } catch (error) {
            throw error
        }
    }

    async deleteMessages(accessToken, idMessage) {
        try {
            const url = `${this.baseApi}/${ENV.API_ROUTES.CHAT}/${idMessage}`
            const params = {
                method: "DELETE",
                headers: {
                    Authorization: `Token ${accessToken}`
                },
            }

            const response = await fetch(url, params)
            const result = await response.json()

            if(response.status !== 200) throw result;
            return result
        } catch (error) {
            throw error
        }
    }
}