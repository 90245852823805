import React from 'react'

export function ReportOptions({content, reference, seeOptions}) {
  return (
    <div className="contentReport">
        <input ref={reference} name='option' className='checkReport' type="radio" />
        <p>{content}</p>
    </div>
  )
}
