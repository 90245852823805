import { ENV } from "../utils"

export class Notification {
    baseApi = ENV.BASE_API;

    async getNotifications(accessToken, id_User) {
        try {
            const url = `${this.baseApi}/${ENV.API_ROUTES.NOTIFICATIONS}/${id_User}`
            const params = {
                headers: {
                    Authorization: `Token ${accessToken}`
                },
            }

            const response = await fetch(url, params)
            const result = await response.json()

            if(response.status !== 200) throw result;
            return result
        } catch (error) {
            throw error
        }
    }

    async createNotification(accessToken, notification){
        try {
            const url = `${this.baseApi}/${ENV.API_ROUTES.NOTIFICATION}`
            const params = {
                method: "POST",
                headers: {
                    Authorization: `Token ${accessToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    title: notification.title,
                    textNotification: notification.textNotification,
                    date: notification.date,
                    id_Work: notification.id_Work,
                    id_User: notification.id_User
                }),
            }

            const response = await fetch(url, params)
            const result = await response.json()

            if(response.status !== 200) throw result;
            return result

        } catch (error) {
            throw error
        }
    }

    async deleteNotification(accessToken, idNotification) {
        try {
            const url = `${this.baseApi}/${ENV.API_ROUTES.NOTIFICATION}/${idNotification}`
            const params = {
                method: "DELETE",
                headers: {
                    Authorization: `Token ${accessToken}`
                },
            }

            const response = await fetch(url, params)
            const result = await response.json()

            if(response.status !== 200) throw result;
            return result
        } catch (error) {
            throw error
        }
    }
}